<script>
import Modal from './Modal.svelte'
import EditDocument from './EditDocument.svelte';
import {editModalStore} from "../../services/stores.js";

function closeModal(){
   editModalStore.set(false);
   
}

</script>

<Modal on:click={closeModal}><EditDocument/></Modal>