<script>
  import { FirebaseService } from "../../services/firebaseService.js";
  import Input from "./Input.svelte";
  import ButtonPink from "./ButtonPink.svelte";
  import { languageList } from "../../services/Languages.js";
  import { docSections } from "../../services/docSections.js";
  import {spaceQuestionMarkRemovePunctuation} from "../../services/utilities.js"

  let wordObj = {"isiZulu":false,
    "isiXhosa":false,
    "English":false,
    "Afrikaans":false,
    "Sesotho sa Leboa":false,
    "Sesotho":false,
    "Xitsonga":false,
    "siSwati":false,
    "Tshivenḓa":false,
    "isiNdebele":false};
  let section = "general";
  let L1_language = "English";
  let L1_word = "";
  let L1_description = "";
  let L1_example = "";
  let L2_language = "isiZulu";
  let L2_word = "";
  let L2_description = "";
  let L2_example = "";

  function reset() {
    wordObj = {"isiZulu":false,
    "isiXhosa":false,
    "English":false,
    "Afrikaans":false,
    "Sesotho sa Leboa":false,
    "Sesotho":false,
    "Xitsonga":false,
    "siSwati":false,
    "Tshivenḓa":false,
    "isiNdebele":false};
    L1_word = "";
    L1_description = "";
    L1_example = "";
    L2_word = "";
    L2_description = "";
    L2_example = "";
  }
  
  function saveWord() {
    L1_word=spaceQuestionMarkRemovePunctuation(L1_word);
    L2_word=spaceQuestionMarkRemovePunctuation(L2_word);
    L2_example=spaceQuestionMarkRemovePunctuation(L2_example);
    L1_example=spaceQuestionMarkRemovePunctuation(L1_example);

    if(L1_description==""){L1_description="N/A"};
    if(L2_description==""){L2_description="N/A"};
    if(L1_example==""){L1_example="N/A"};
    if(L2_example==""){L2_example="N/A"};
    wordObj[L1_language]=true;
    wordObj[L2_language]=true;
    wordObj[`section`] = section;
    wordObj[`${L1_language}_word`] = L1_word;
    wordObj[`${L1_language}_description`] = L1_description;
    wordObj[`${L1_language}_example`] = L1_example;
    wordObj[`${L2_language}_word`] = L2_word;
    wordObj[`${L2_language}_description`] = L2_description;
    wordObj[`${L2_language}_example`] = L2_example;
    
    FirebaseService.addWord(wordObj);
    reset();
  }
</script>

<p>Select the section e.g general , medical</p>
<select
  bind:value={section}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
    text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
    focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each docSections as asection}
    <option>{asection}</option>
  {/each}
</select>
<p>Select the language you want to translate FROM</p>
<select
  bind:value={L1_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
    text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
    focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<Input
  placeholder="Enter word in {L1_language}"
  label="{L1_language}_word"
  bind:value={L1_word} />
<Input
  placeholder="description e.g noun verb adjective"
  label="{L1_language}_description"
  bind:value={L1_description} />
<Input
  placeholder="example sentence using word"
  label="{L1_language}_example"
  bind:value={L1_example} />

<br />
<br />
<hr />
<br />
<br />
<p>Select the language you want to translate TO</p>
<select
  bind:value={L2_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
    text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
    focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<Input
  placeholder="Enter word in {L2_language}"
  label="{L2_language}_word"
  bind:value={L2_word} />
<Input
  placeholder="description e.g noun verb adjective"
  label="{L2_language}_description"
  bind:value={L2_description} />
<Input
  placeholder="example sentence using word"
  label="{L2_language}_example"
  bind:value={L2_example} />
<br />
<br />
<ButtonPink text="Save" color="pink" on:click={saveWord} />
