import { db } from './firebaseInit.js'

export class FirebaseService {    

    static async getDocumentDataWords(word, original_language,translated_language) {
        let dataArray = [];
        let  original_language_word= original_language+"_word";
        const query = db.collection('words').where( original_language_word, '==', word).where(translated_language,'==',true);
        await query.get()
            .then((docs) => {
                docs.forEach((doc) => {
                    let docObj = doc.data();
                    docObj['id'] = doc.id;
                    dataArray.push(docObj);
                });
            }).catch((error) => { console.log(error) })
        return dataArray;
    }
    static async getDocumentDataSentences(word, original_language,translated_language) {
        let dataArray = [];
        let original_language_word_array=original_language+"_array";
        const query = db.collection('sentences').where(original_language_word_array, 'array-contains', word).where(translated_language,'==',true);
        await query.get()
            .then((docs) => {
                docs.forEach((doc) => {
                    let docObj = doc.data();
                    docObj['id'] = doc.id;
                    dataArray.push(docObj);
                });
            }).catch((error) => { console.log(error) })
        return dataArray;
    }
    static async getDocumentDataSectionSentence(section) {
        let dataArray = [];        
        const query = db.collection('sentences')          
            .where('section','==',section);
        await query.get()
            .then((docs) => {
                docs.forEach((doc) => {
                    let docObj = doc.data();
                    docObj['id'] = doc.id;
                    dataArray.push(docObj);
                });
            }).catch((error) => { console.log(error) })
        return dataArray;
    }
    static async getDocumentDataSectionWord(section) {
        let dataArray = [];        
        const query = db.collection('words')          
            .where('section','==',section);
        await query.get()
            .then((docs) => {
                docs.forEach((doc) => {
                    let docObj = doc.data();
                    docObj['id'] = doc.id;
                    dataArray.push(docObj);
                });
            }).catch((error) => { console.log(error) })
        return dataArray;
    }

    static addWord(docObj) {        
        db.collection("words").add(docObj);
    }
    static updateWord(docObj) {
        
        
        let docRef = db.collection("words").doc(docObj['id']);
       
        docRef.set(docObj,{ merge: true })
    }
    static updateSentence(docObj) {
        
        
        let docRef = db.collection("sentences").doc(docObj['id']);
       
        docRef.set(docObj,{ merge: true })
    }

    static addSentence(docObj) {   
        
        db.collection("sentences")
            .add(docObj)
            .then((data) => console.log(data))
            .catch((err)=>console.log(err));
    }

    static  getUntranslatedWords(fromLanguage,toLanguage){  
         return db.collection("words")
        .where(fromLanguage,"==",true)
        .where(toLanguage,"==",false)       
        .get()    
    }

    static  getUntranslatedSentences(fromLanguage,toLanguage){  
         return db.collection("sentences")
        .where(fromLanguage,"==",true)
        .where(toLanguage,"==",false)                 
        .get()    
    }

    
}