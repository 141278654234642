export let docStructureWord = [
    "English_word",
    "English_word_description",
    "English_word_example",

    "isiZulu_word",
    "isiZulu_word_description",
    "isiZulu_word_example",

    "isiXhosa_word",
    "isiXhosa_word_description",
    "isiXhosa_word_example",

    "Afrikaans_word",    
    "Afrikaans_word_description",
    "Afrikaans_word_example",

    "Sesotho sa Leboa_word",    
    "Sesotho sa Leboa_word_description",
    "Sesotho sa Leboa_word_example",

    "Sesotho_word",
    "Sesotho_word_description",
    "Sesotho_word_example",

    "Xitsonga_word",
    "Xitsonga_word_description",
    "Xitsonga_word_example",

    "siSwati_word",
    "siSwati_word_description",
    "siSwati_word_example",

    "Tshivenḓa_word",    
    "Tshivenḓa_word_description",
    "Tshivenḓa_word_example",

    "isiNdebele_word",    
    "isiNdebele_word_description",
    "isiNdebele_word_example"    
]