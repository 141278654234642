<script>
  import { FirebaseService } from "../../services/firebaseService.js";
  import Input from "./Input.svelte";
  import ButtonPink from "./ButtonPink.svelte";
  import { languageList } from "../../services/Languages.js";
   import {docSections} from "../../services/docSections.js";
   import {spaceQuestionMarkRemovePunctuation} from "../../services/utilities.js"
  let sentenceObj={ "isiZulu":false,
    "isiXhosa":false,
    "English":false,
    "Afrikaans":false,
    "Sesotho sa Leboa":false,
    "Sesotho":false,
    "Xitsonga":false,
    "siSwati":false,
    "Tshivenḓa":false,
    "isiNdebele":false};
  let section="general";
  let L1_language = "English";
  let L1_sentence = "";
  let L1_description = "";
  
  let L2_language = "isiZulu";
  let L2_sentence = "";
  let L2_description = "";
  

function reset(){
   sentenceObj={ "isiZulu":false,
    "isiXhosa":false,
    "English":false,
    "Afrikaans":false,
    "Sesotho sa Leboa":false,
    "Sesotho":false,
    "Xitsonga":false,
    "siSwati":false,
    "Tshivenḓa":false,
    "isiNdebele":false};   
   L1_sentence = "";
   L1_description = "";     
   L2_sentence = "";
   L2_description = "";
}

  function saveSentence() {
   L1_sentence=spaceQuestionMarkRemovePunctuation(L1_description);
   L2_sentence=spaceQuestionMarkRemovePunctuation(L2_description);
    if(L1_description==""){L1_description="N/A"}
    if(L2_description==""){L2_description="N/A"}
    sentenceObj[L1_language]=true;
    sentenceObj[L2_language]=true;
    sentenceObj[`section`] = section;
    sentenceObj[`${L1_language}_sentence`] = L1_sentence;
    sentenceObj[`${L1_language}_sentence_description`] = L1_description;    
    sentenceObj[`${L2_language}_sentence`] = L2_sentence;
    sentenceObj[`${L2_language}_sentence_description`] = L2_description;  
    sentenceObj[`${L1_language}_array`] = sentenceObj[`${L1_language}_sentence`].split(" ");
    sentenceObj[`${L2_language}_array`] = sentenceObj[`${L2_language}_sentence`].split(" ");
    FirebaseService.addSentence(sentenceObj);
    reset();
  }
</script>

<style>

</style>
<p>Select the section e.g general , medical</p>
<select
  bind:value={section}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight
  focus:outline-none focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each docSections as asection}
    <option>{asection}</option>
  {/each}
</select>
<p>Select the language you want to translate FROM</p>
<select
  bind:value={L1_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
  focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<Input
  placeholder="Enter sentence in {L1_language}"
  label="{L1_language}_sentence"
 bind:value={L1_sentence} />
<Input
  placeholder="optional : use to describe context of sentence"
  label="{L1_language}_sentence_description"
  bind:value={L1_description} />

<br />
<br />
<hr />
<br />
<br>
<p>Select the language you want to translate TO</p>
<select
  bind:value={L2_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
  focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<Input
  placeholder="Enter sentence in {L2_language}"
  label="{L2_language}_sentence"
  bind:value={L2_sentence} />
<Input
  placeholder="optional : use to describe context of sentence"
  label="{L2_language}_sentence_description"
  bind:value={L2_description} />
<br />
<br />
<ButtonPink text="Save"  on:click={saveSentence} />
