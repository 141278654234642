export let docSections = [
    "general",
    "medical",
    "romance",
    "proverbs",
    "clan praises",
    "culture",    
    "finance",
    "maths",
    "science"   
]