<script>
  import { FirebaseService } from "../../services/firebaseService.js";
  import Input from "./Input.svelte";
  import ButtonPink from "./ButtonPink.svelte";
  import { languageList } from "../../services/Languages.js";
  import {docSections} from "../../services/docSections.js";
  import {spaceQuestionMarkRemovePunctuation} from "../../services/utilities.js"

  let original_language;
  let docObj = {
    "isiZulu":false,
    "isiXhosa":false,
    "English":false,
    "Afrikaans":false,
    "Sesotho sa Leboa":false,
    "Sesotho":false,
    "Xitsonga":false,
    "siSwati":false,
    "Tshivenḓa":false,
    "isiNdebele":false
  };
  let sentence = "";
  let sentence_description = "";
  let section = "general";

  function reset() {
    docObj = { "isiZulu":false,
    "isiXhosa":false,
    "English":false,
    "Afrikaans":false,
    "Sesotho sa Leboa":false,
    "Sesotho":false,
    "Xitsonga":false,
    "siSwati":false,
    "Tshivenḓa":false,
    "isiNdebele":false};
    sentence = "";
    sentence_description = "";
  }
  
  function saveSentence() {
    sentence=spaceQuestionMarkRemovePunctuation(sentence);
    if(sentence_description==""){sentence_description="N/A"}
    docObj[`${original_language}_sentence`] = sentence;
    docObj[`${original_language}_sentence_description`] = sentence_description;
    docObj[`section`] = section;
    docObj[original_language]=true;
    docObj[`${original_language}_array`] =sentence.split(" ");
    FirebaseService.addSentence(docObj);
   reset();
  }
</script>
<p>Select the section e.g general , medical</p>
<select
  bind:value={section}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight
  focus:outline-none focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each docSections as asection}
    <option>{asection}</option>
  {/each}
</select>
<p>Select the language of the sentence</p>
<select
  bind:value={original_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight
  focus:outline-none focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<Input
  placeholder="Enter sentence in {original_language}"
  label="Sentence"
  bind:value={sentence} />
<Input
  placeholder="optional : use to describe context of sentence"
  label="Description"
  bind:value={sentence_description} />

<br />
<br />
<ButtonPink text="Save" on:click={saveSentence} />
