<script>
  export let wordOrSentenceArray;
  import { languageList } from "../../services/Languages.js";
  import ButtonPink from "./ButtonPink.svelte";
  import { editModalStore, docStore } from "../../services/stores.js";

  function edit(doc) {
    docStore.set(doc);
    editModalStore.set(true);
  }
</script>

<style>
  .row {
    display: grid;   
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
  }
  /* .row:nth-child(even) {
    background-color: #fdfdfd;
  } */
  .row-color {
    background-color: #f0f1ff;
  }
</style>

{#each wordOrSentenceArray as doc, i}
  {#each languageList as language,iw}
    {#if doc[language + '_word']}
      <div class="{iw%2 === 1 ? 'row' : 'row-color'}">
       

        <div>{doc[language + '_word']}</div>
        {#if doc[language + '_description']}
          <div>{doc[language + '_description']}</div>
        {/if}
        {#if doc[language + '_example']}
          <div>{doc[language + '_example']}</div>
        {/if}
      </div>
    {/if}
  {/each}
  {#each languageList as language,is}
    {#if doc[language + '_sentence']}
      <div class="{is%2 === 1 ? 'row' : 'row-color'}">     
        <div>{doc[language + '_sentence']}</div>
        {#if doc[language + '_description']}
          <div>{doc[language + '_description']}</div>
        {/if}
      </div>
    {/if}
  {/each}
  <ButtonPink text="Edit" on:click={edit(wordOrSentenceArray[i])} />
  <br>
  <br>
  <hr>
  <br>

{/each}
