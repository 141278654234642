<script>
	import {FirebaseService} from './services/firebaseService.js'
	import SearchOptions from './components/UI/SearchOptions.svelte'
	import Search from './components/UI/Search.svelte'
	import NavBar from './components/UI/NavBar.svelte'
	import Add from './components/UI/Add.svelte'
	import AddWord from './components/UI/AddWord.svelte'
	import AddWordAndTranslation from './components/UI/AddWordAndTranslation.svelte'
	import Translate from './components/UI/Translate.svelte'	
	import EditModal from './components/UI/EditModal.svelte'
	import AddSentenceAndTranslation from './components/UI/AddSentenceAndTranslation.svelte'
	import AddSentence from './components/UI/AddSentence.svelte'
	import EditDocument from './components/UI/EditDocument.svelte'
	import { editModalStore,showStore} from "./services/stores.js";	
</script>

<NavBar/>
<main class="m-auto w-11/12 lg:w-1/2">
{#if $showStore=="SearchOptions"}
<SearchOptions/>
{/if}
{#if $showStore=="Search"}
<Search/>
{/if}
{#if $showStore=="Add"} 
	 <Add/>
{/if}
{#if $showStore=="AddWord"}
	 <AddWord/>
{/if}
{#if $showStore=="AddSentence"}
	 <AddSentence/>
{/if}
{#if $showStore=="AddWordAndTranslation"}
	 <AddWordAndTranslation/>
{/if}

{#if $showStore=="Translations"}
	 <Translate/>
{/if}

{#if $showStore=="AddSentenceAndTranslation"}
	 <AddSentenceAndTranslation/>
{/if}
{#if $editModalStore===true}
	 <EditModal/>
{/if}

</main>

