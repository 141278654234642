export let docStructureSentence = [
    "English_sentence",
    "English_sentence_description",

    "isiZulu_sentence",
    "isiZulu_sentence_description",

    "isiXhosa_sentence",
    "isiXhosa_sentence_description",

    "Afrikaans_sentence",
    "Afrikaans_sentence_description",

    "Sesotho sa Leboa_sentence",
    "Sesotho sa Leboa_sentence_description",

    "Sesotho_sentence",
    "Sesotho_sentence_description",

    "Xitsonga_sentence",
    "Xitsonga_sentence_description",

    "siSwati_sentence",
    "siSwati_sentence_description",

    "Tshivenḓa_sentence",
    "Tshivenḓa_sentence_description",

    "isiNdebele_sentence",
    "isiNdebele_sentence_description",

]