<script>
  import ButtonTeal from "./ButtonTeal.svelte";
  import ButtonDarkTeal from "./ButtonDarkTeal.svelte";
  import { showStore , optionsStore} from "../../services/stores.js";
  
  function searchSection(){
    optionsStore.set("searchSection");
    showStore.set("Search");
  }
  function searchWord(){
    optionsStore.set("searchWord");
    showStore.set("Search");
  }

</script>

<ButtonTeal on:click={searchSection} text="Search by section" />
<br />
<ButtonDarkTeal on:click={searchWord} text="Search for word" />
<br />