<script>
  export let placeholder="";
  export let label="";
  export let value="";

 
</script>

<input
  on:input  
  class="appearance-none bg-transparent  w-full text-gray-700 mr-3
   px-2 leading-tight focus:outline-none border-b border-b-2 border-teal-500 py-4"
  type="text"
  bind:value={value}
  {placeholder}
  aria-label={label} />
  <div class="text-xs text-teal-500">{label}</div>
  
