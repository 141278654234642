import App from './App.svelte';

const app = new App({
	target: document.body	
});

export default app;


/* import App from './AppTest.svelte';

const app = new App({
	target: document.body	
});

export default app; */