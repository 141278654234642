<script>
 
  import ButtonTeal from "./ButtonTeal.svelte";
  import ButtonDarkTeal from "./ButtonDarkTeal.svelte";
  import { showStore } from "../../services/stores.js";

  function AddWord() {
    showStore.set("AddWord");
  }
  function AddWordAndTranslation() {
    showStore.set("AddWordAndTranslation");
  }
  function Translations() {
    showStore.set("Translations");
  }
  function AddSentence() {
    showStore.set("AddSentence");
  }
  function AddSentenceAndTranslation() {
    showStore.set("AddSentenceAndTranslation");
  }
  
</script>

<ButtonTeal on:click={AddWord} text="Add word only" />
<br />
<ButtonDarkTeal on:click={AddWordAndTranslation} text="Add word and translation" />
<br />
<ButtonTeal on:click={AddSentence} text="Add sentence only" />
<br />
<ButtonDarkTeal on:click={AddSentenceAndTranslation} text="Add sentence and translation" />
<br />
<ButtonTeal on:click={Translations} text="Do translations only" />
