<script>
  import { FirebaseService } from "../../services/firebaseService.js";
  import ButtonPink from "./ButtonPink.svelte";
  import { onDestroy } from "svelte";
  import { docStore } from "../../services/stores.js";
  import Input from "./Input.svelte";
  import { docStructureWord } from "../../services/docStructureWord.js";
  import { docStructureSentence } from "../../services/docStructureSentence.js";
  import { docSections } from "../../services/docSections";

  let tempDoc;
  let section;
  $: tempDoc["section"] = section;
  const unsubscribe = docStore.subscribe((value) => {
    tempDoc = value;
    section = tempDoc["section"];
  });

  onDestroy(unsubscribe);

  function updateVar(event) {
    let key = event.target.ariaLabel;
    let val = event.target.value;
    tempDoc[key] = val;
  }

  function updateDoc() {
    FirebaseService.updateWord(tempDoc);
  }
</script>

<select
  bind:value={section}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
    text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
    focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each docSections as asection}
    <option>{asection}</option>
  {/each}
</select>
{#each docStructureWord as field}
  {#if tempDoc[field]}
    <Input value={tempDoc[field]} label={field} on:input={updateVar} />
    <br />
  {/if}
{/each}
{#each docStructureSentence as field}
  {#if tempDoc[field]}
    <Input value={tempDoc[field]} label={field} on:input={updateVar} />
    <br />
  {/if}
{/each}
<ButtonPink on:click={updateDoc} text="Save" />
