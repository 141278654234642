<script>
  import { languageList } from "../../services/Languages.js";
  import { FirebaseService } from "../../services/firebaseService.js";
  import Input from "./Input.svelte";
  import ButtonPink from "./ButtonPink.svelte";
  import ButtonPurple from "./ButtonPurple.svelte";

  let toLanguage = "isiZulu";

  let untranslatedWordList = [];
  let untranslatedSentenceList = [];
  let fromLanguage = "English";

  let word = "";
  let word_description = "";
  let word_example = "";
  let sentence = "";
  let sentence_description = "";

  function spaceQuestionMarkRemovePunctuation(sentence){    
    sentence=sentence.toLowerCase();
    sentence=sentence.replace('?',' ?');
    sentence=sentence.replace('!',' !');
    sentence=sentence.replace('.',' ');
    sentence=sentence.trim();
    return sentence;

  }

  function getUntranslated() {
    getUntranslatedWords();
    getUntranslatedSentences();
  }

  function getUntranslatedWords() {
    untranslatedWordList = [];
    
    FirebaseService.getUntranslatedWords(fromLanguage, toLanguage).then(
      (docs) => {
        docs.forEach((doc) => {
          let docObj = doc.data();
          docObj["id"] = doc.id;
          untranslatedWordList.push(docObj);
        });
        untranslatedWordList = untranslatedWordList;
      
      }
    );
  }

  function getUntranslatedSentences() {
    untranslatedSentenceList = [];
    FirebaseService.getUntranslatedSentences(fromLanguage, toLanguage).then(
      (docs) => {
        docs.forEach((doc) => {
          let docObj = doc.data();
          docObj["id"] = doc.id;
          untranslatedSentenceList.push(docObj);
        });
        untranslatedSentenceList = untranslatedSentenceList;
      }
    );
  }

  function updateVar(event) {
    let label = event.target.ariaLabel;
    let val = event.target.value;
    switch (label) {
      case "word":
        word = val;
        break;
      case "word_description":
        word_description = val;
        break;
      case "word_example":
        word_example = val;
        break;
      case "sentence":
        sentence = val;
        break;
      case "sentence_description":
        sentence_description = val;
        break;
    }
  }

  function saveSentenceTranslation(docObj) {  
    if (sentence) {
      sentence=spaceQuestionMarkRemovePunctuation(sentence);
      docObj[toLanguage + "_sentence"] = sentence;
      docObj[toLanguage + "_sentence_description"] = sentence_description;
      docObj[toLanguage] = true;     
      FirebaseService.updateSentence(docObj);
      getUntranslatedSentences();
    }
  }

  function saveWordTranslation(docObj) {
    word=spaceQuestionMarkRemovePunctuation(word);
    if (word) {
      docObj[toLanguage + "_word"] = word;
    }
    if (description) {
      docObj[toLanguage + "_description"] = word_description;
    }
    if (example) {
      docObj[toLanguage + "_example"] = word_example;
    }
    docObj[toLanguage] = true;
    FirebaseService.updateWord(docObj);
    getUntranslatedWords();
  }
</script>

<style>
  .container {
    display: flex;
    flex-direction: column;
  }

  .sentence-container {
    order: 1;
  }
  .sentence-description-container {
    order: 2;
  }
  .row-word {
    order: 2;
  }
  .row-input-word {
    order: 3;
  }
  .row-description {
    order: 4;
  }
  .row-input-description {
    order: 5;
  }
  .row-example {
    order: 6;
  }
  .row-input-example {
    order: 7;
  }
  .row-button {
    order: 8;
  }
</style>

<p>Select the language you want to translate FROM</p>
<select
  bind:value={fromLanguage}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
  focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<br />
<p>Select the language you want to translate TO</p>
<select
  bind:value={toLanguage}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
  focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<br />
<ButtonPurple text="SEARCH" on:click={getUntranslated} />

{#each untranslatedWordList as untranslated, i (untranslated['id'])}
  <div class="container">
    <br />
    <br />

    {#each Object.entries(untranslated) as prop}
      {#if prop[0] === fromLanguage + '_word'}
        <div class="row-word">{prop[1]}</div>
        <div class="row-input-word">
          <Input
            on:input={updateVar}
            label="word"
            placeholder="Enter {toLanguage} translation" />
          <br />
        </div>
        <div class="row-button">
          <ButtonPink
            on:click={saveWordTranslation(untranslated)}
            text="Save Translation" />
        </div>
      {/if}
      {#if prop[0] === fromLanguage + '_description'&&prop[1]!='N/A'}
        <div class="row-description">{prop[1]}</div>
        <div class="row-input-description">
          <Input
            on:input={updateVar}
            label="word_description"
            placeholder="Enter {toLanguage} description" />
          <br />
        </div>
      {/if}
      {#if prop[0] === fromLanguage + '_example'}
        <div class="row-example">{prop[1]}</div>
        <div class="row-input-example">
          <Input
            on:input={updateVar}
            label="word_example"
            placeholder="Enter {toLanguage} translation" />
          <br />
        </div>
      {/if}
    {/each}
  </div>
  <br />
  <br />
{/each}

{#each untranslatedSentenceList as untranslated, i (untranslated['id'])}
  <div class="container">
    {#each Object.entries(untranslated) as prop}
      {#if prop[0] === fromLanguage + '_sentence'}
        <div class="sentence-container">
          <div class="row-sentence">{prop[1]}</div>
          <div class="row-input-word">
            <Input
              on:input={updateVar}
              label={'sentence'}
              placeholder="Enter {toLanguage} translation" />
            <br />
          </div>
        </div>
      {/if}
      {#if prop[0] === fromLanguage + '_sentence_description'&&prop[1]!='N/A'}
        <div class="sentence-description-container">
          <div class="row-sentence-description">{prop[1]}</div>
          <div class="row-input-word">
            <Input
              on:input={updateVar}
              label={'sentence_description'}
              placeholder="Enter {toLanguage} translation" />
            <br />
          </div>
        </div>
      {/if}
    {/each}
    <div class="row-button">
      <ButtonPink
        on:click={saveSentenceTranslation(untranslated)}
        text="Save Translation" />
    </div>
  </div>
  <br />
  <br />
{/each}
