export let languageList = [
    "English",
    "isiZulu",
    "isiXhosa",    
    "Afrikaans",
    "Sesotho sa Leboa",
    "Sesotho",
    "Xitsonga",
    "siSwati",
    "Tshivenḓa",
    "isiNdebele"

]