<script>  
  import {    
    showStore
  } from "../../services/stores.js";

  function displaySearchOptions() {
    showStore.set("SearchOptions");
    
  }
  function displayAdd() {
    showStore.set("Add");    
  }
</script>

<div class="flex flex-row">
  <div class="text-center text-4xl flex-grow">FundaNyana</div>
  <div>
    <span on:click={displayAdd}>
      <img class="h-8 w-8 m-3" src="./assets/icons/add-24px.svg" alt="" />
    </span>
  </div>
  <span on:click={displaySearchOptions}>
    <img class="h-8 w-8 m-3" src="./assets/icons/search-24px.svg" alt="" />
  </span>
</div>
<hr />
<br />
