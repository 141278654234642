import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
var firebaseConfig = {
    apiKey: "AIzaSyAKO2awLXRSm4XO3WRJ0KAA-XcVbqsOmDI",
    authDomain: "fundanyana.firebaseapp.com",
    databaseURL: "https://fundanyana.firebaseio.com",
    projectId: "fundanyana",
    storageBucket: "fundanyana.appspot.com",
    messagingSenderId: "943352895053",
    appId: "1:943352895053:web:353338c58805dd07"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();

export const db = firebase.firestore();