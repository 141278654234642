<script>
  import { FirebaseService } from "../../services/firebaseService.js";
  import Input from "./Input.svelte";
  import ButtonPink from "./ButtonPink.svelte";
  import { languageList } from "../../services/Languages.js";
  import { docSections } from "../../services/docSections.js";
  import {spaceQuestionMarkRemovePunctuation} from "../../services/utilities.js"

  let original_language;
  let docObj = { "isiZulu":false,
    "isiXhosa":false,
    "English":false,
    "Afrikaans":false,
    "Sesotho sa Leboa":false,
    "Sesotho":false,
    "Xitsonga":false,
    "siSwati":false,
    "Tshivenḓa":false,
    "isiNdebele":false};
  let word = "";
  let word_description = "";
  let word_example = "";
  let section = "general";

  function reset() {
    docObj = { "isiZulu":false,
    "isiXhosa":false,
    "English":false,
    "Afrikaans":false,
    "Sesotho sa Leboa":false,
    "Sesotho":false,
    "Xitsonga":false,
    "siSwati":false,
    "Tshivenḓa":false,
    "isiNdebele":false};
    word = "";
    word_description = "";
    word_example = "";
  }
  

  function saveWord() {
    word=spaceQuestionMarkRemovePunctuation(word);
    word_example=spaceQuestionMarkRemovePunctuation(word_example);
    if(word_description==""){word_description="N/A"};
    if(word_example==""){word_example="N/A"};
    docObj[`${original_language}_word`] = word;
    docObj[`${original_language}_description`] = word_description;
    docObj[`${original_language}_example`] = word_example;
    docObj[`section`] = section;
    docObj[original_language]=true;
    FirebaseService.addWord(docObj);
    reset();
  }
</script>

<p>Select the section e.g general , medical</p>
<select
  bind:value={section}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
    text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
    focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each docSections as asection}
    <option>{asection}</option>
  {/each}
</select>
<p>Select the language of the word</p>
<select
  bind:value={original_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
    text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
    focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<Input
  bind:value={word}
  placeholder="Enter word in {original_language}"
  label="Word" />
<Input
  bind:value={word_description}
  placeholder="description e.g noun verb adjective"
  label="Description" />
<Input
  bind:value={word_example}
  placeholder="example sentence using the word"
  label="Example" />

<br />
<br />
<ButtonPink text="Save" color="pink" on:click={saveWord} />
