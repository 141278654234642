<script>
  import Input from "./Input.svelte";
  import ButtonPurple from "./ButtonPurple.svelte";
  import ResultTableSearch from "./ResultTableSearch.svelte";
  import { languageList } from "../../services/Languages.js";
  import { FirebaseService } from "../../services/firebaseService.js";
  import {docSections} from "../../services/docSections";
  import {optionsStore} from "../../services/stores"
  let wordArray = [];
  let cleanWordArray = [];
  let sentenceArray = [];
  let cleanSentenceArray = [];
  let word = "";
  let section="general";

  let original_language="English";
  let translated_language="isiZulu";

  function cleanWord() {
    wordArray.forEach(doc => {
      let cleanDoc={};
      for (const key in doc) {
        switch (key) {
          case `${original_language}_word`:
          case `${original_language}_description`:
          case `${original_language}_example`:
          case `${translated_language}_word`:
          case `${translated_language}_description`:
          case `${translated_language}_example`:
            cleanDoc[key] = doc[key];
            break;
          default:
            break;
        }
      }
      cleanWordArray.push(cleanDoc);
      
    });
    
    cleanWordArray=cleanWordArray;
    
  }
  function cleanSentence() {
    
    sentenceArray.forEach(doc => {
      let cleanDoc={};
      for (const key in doc) {
        switch (key) {
          case `${original_language}_sentence`:
          case `${original_language}_sentence_description`:          
          case `${translated_language}_sentence`:
          case `${translated_language}_sentence_description`:          
            cleanDoc[key] = doc[key];
            break;
          default:
            break;
        }
      }
      cleanSentenceArray.push(cleanDoc);
      
    });
    
    cleanSentenceArray=cleanSentenceArray;
    
  }

  function reset(){
    cleanWordArray = [];
    cleanWordArray=cleanWordArray;
    cleanSentenceArray = [];
    cleanSentenceArray=cleanSentenceArray;
  }

  function findWordAndSentences() {
    reset();
    FirebaseService.getDocumentDataWords(word,original_language,translated_language).then(
      data => {
      wordArray = data;
        
	  cleanWord();
    });
    FirebaseService.getDocumentDataSentences(word, original_language,translated_language).then(
      data => {
        sentenceArray = data;
        
		cleanSentence();
      }
    );
  }
  function findWordAndSentenceInSection() {
    reset();
    FirebaseService.getDocumentDataSectionWord(section).then(
      data => {
      wordArray = data;
      	  
	  cleanWord();
    });
    FirebaseService.getDocumentDataSentences(section).then(
      data => {
        sentenceArray = data;
        
		cleanSentence();
      }
    );
  }
</script>
{#if $optionsStore=="searchSection"}
<p>Select the language of the word</p>
<select
  bind:value={original_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
  focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<p>Select the language of the translation</p>
<select
  bind:value={translated_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
  focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<p>Select the section e.g general , medical</p>
<select
  bind:value={section}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight
  focus:outline-none focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each docSections as asection}
    <option>{asection}</option>
  {/each}
</select>
<ButtonPurple on:click={findWordAndSentenceInSection} text="SHOW ALL IN THIS SECTION" />
{/if}
{#if $optionsStore=="searchWord"}
<p>Select the language of the word</p>
<select
  bind:value={original_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
  focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<p>Select the language of the translation</p>
<select
  bind:value={translated_language}
  class="block appearance-none w-full bg-gray-200 border border-gray-200
  text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none
  focus:bg-white focus:border-gray-500"
  id="grid-state">
  {#each languageList as language}
    <option>{language}</option>
  {/each}
</select>
<Input
  placeholder="Enter word"
  label="Search"
  on:input={event => {
    word = event.target.value;
  }} />

<ButtonPurple on:click={findWordAndSentences} text="SEARCH" />
<br />
<br />
<hr>
{/if}




<ResultTableSearch wordOrSentenceArray={cleanWordArray} />
<ResultTableSearch wordOrSentenceArray={cleanSentenceArray} />
